import React from 'react';
import styled from 'styled-components';

import { Outer } from './post';
import Container from '../components/Container';
import FadeEntry from '../components/FadeEntry';
import MetaData from '../components/MetaData';
import PageParent from '../components/Page';
import Title from '../components/Title';

const PageTemplate = ({ pageContext: { title, acf } }) => {
	return (
		<Outer>
			<MetaData
				title={acf && acf.seo_title ? acf.seo_title : title}
				description={acf && acf.seo_description ? acf.seo_description : null}
				images={acf && acf.seo_images ? acf.seo_images : null}
			/>

			<Page>
				<Container
					size={'narrow'}
				>
					{ title ? (
						<FadeEntry
							className={'section__title'}
						>
							<Title
								size={'titleMd'}
							>{ title }</Title>
						</FadeEntry>
					) : null }

					<FadeEntry>
						<div
							className="user-content"
							dangerouslySetInnerHTML={{ __html: acf.content }}
						/>
					</FadeEntry>
				</Container>
			</Page>
		</Outer>
	);
}

const Page = styled(PageParent)`
	.section__title {
		${Title} {
			font-size: ${({ theme }) => theme.fontSize.titleLg};
		}
			
		+* {
			margin-top: 40px;
		}
	}
`;

export default PageTemplate;
